<template>
  <div v-if="showBirthdayMessage" :class="`birthday-message ${classMessage}`">
    <img src="../assets/hbd.png" alt="Feliz Cumpleaños" />
  </div>
  <audio controls autoplay ref="audioPlayer">
    <source src="../assets/those-eyes.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
  <div v-if="showText" class="love-message">{{ typedText }}</div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const showBirthdayMessage = ref(true);
const classMessage = ref(false);
const showText = ref(false);
const typedText = ref("");
const fullText =
  "Te amo de aquí a la luna, ida y vuelta a 999mil pasitos de tortuguita en tacones... \n ¿Sabes como caminan las tortugas en tacones wey? lento, lentisimo, tardan 1000 años, asi asi asi te amo... ";

const audioPlayer = ref(null);

onMounted(() => {
  classMessage.value = "show";
  setTimeout(() => {
    showBirthdayMessage.value = false;
    showText.value = true;
    classMessage.value = "";
    typeText();
  }, 10000); // Espera 10 segundos, luego muestra el mensaje de amor y comienza la música
});

const typeText = () => {
  let i = 0;
  const interval = setInterval(() => {
    if (i < fullText.length) {
      typedText.value += fullText[i];
      i++;
    } else {
      clearInterval(interval);
    }
  }, 100); // Ajusta la velocidad de "escritura" aquí
};
</script>

<style>
.birthday-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 1s ease-in-out;
}

.birthday-message img {
  width: 300px; /* o el tamaño que prefieras */
}

.birthday-message.show {
  animation: show 3s forwards;
}
@keyframes show {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
  }
}

.love-message {
  /* Estilos para el mensaje de amor */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  max-width: 40%;
}

/* Asegúrate de que el reproductor de audio no sea visible */
audio {
  display: none;
}
</style>
