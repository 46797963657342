<template>
  <div>
    <img src="../assets/turtle.gif" alt="" />
  </div>
</template>
<script setup></script>
<style scoped>
div {
  position: absolute;
  left: 20px;
  bottom: 20px;
  overflow: hidden;
  width: 10%;
  min-width: 150px;
  aspect-ratio: 16/12;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  transform: scaleX(-1);
  width: 100%;
}
</style>
