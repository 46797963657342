<template>
  <div class="star" :style="starStyle">
    <img src="../assets/star.svg" alt="" />
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const starStyle = ref("");

const props = defineProps({
  top: String,
  bottom: String,
  left: String,
  right: String,
  width: String,
});

starStyle.value = `position: absolute; top: ${props.top}%; bottom: ${props.bottom}%; left: ${props.left}%; right: ${props.right}%; width: ${props.width}px;`;
</script>

<style scoped>
.star img {
  display: block;
}
</style>
