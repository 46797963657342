<template>
  <div>
    <img src="../assets/moon.svg" alt="" />
  </div>
</template>
<script setup></script>
<style scoped>
div {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20%;
  min-width: 200px;
}
img {
  width: 100%;
  animation: rotateMoon 30000ms infinite ease;
}
@keyframes rotateMoon {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(35deg);
  }
  75% {
    transform: rotate(-35deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
